<template>
  <section class="look">
    <div class="container">
      <div class="look__title">
        <h1>{{ $t('cases_page.kultura_kavy.title') }}</h1>
      </div>
      <div class="look__text">{{ $t('cases_page.kultura_kavy.desc') }}</div>
    </div>
  </section>

  <section class="presentation">
    <div class="container">
      <picture>
        <source srcset="../assets/cases/kulturaKavy/cover_Kultura_Kavy.webp" type="image/webp">
        <img class="presentation__image" src="../assets/cases/kulturaKavy/cover_Kultura_Kavy.png" alt="" loading="lazy" />
      </picture>
    </div>
  </section>

  <section class="about">
    <div class="container">
      <div class="line-wrapper">
        <div class="about__inner">
          <div class="about__tech">
            <div class="about__tech-item">
              <div class="about__tech-desc">
                {{ $t('cases_page.kultura_kavy.category') }}
              </div>
              <span>{{ $t('cases_page.kultura_kavy.category_list') }}</span>
            </div>
            <div class="about__tech-item">
              <div class="about__tech-desc">{{ $t('cases_page.kultura_kavy.services') }}</div>
              <span>{{ $t('cases_page.kultura_kavy.services_list') }}</span>
            </div>
            <div class="about__tech-item">
              <div class="about__tech-desc">{{ $t('cases_page.kultura_kavy.about_tech') }}</div>
              <span>{{ $t('cases_page.kultura_kavy.about_tech_list') }}</span>
            </div>
          </div>
          <div class="about__info">
            <div>
              <h2 class="about__info-title">{{ $t('cases_page.kultura_kavy.about_info') }}</h2>
            </div>
            <p class="about__info-text">{{ $t('cases_page.kultura_kavy.about_info_desc') }}</p>
            <div class="about__info-btn" v-for="(project, i) in cases" :key="i">
              <a class="btn__general btn__general-black" :href="project.path" rel="nofollow" target="_blank">
                {{ $t('cases_page.kultura_kavy.project_link') }}
                <svg width="31" height="8" viewBox="0 0 31 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M30.3536 4.35355C30.5488 4.15829 30.5488 3.84171 30.3536 3.64645L27.1716 0.464466C26.9763 0.269204 26.6597 0.269204 26.4645 0.464466C26.2692 0.659728 26.2692 0.976311 26.4645 1.17157L29.2929 4L26.4645 6.82843C26.2692 7.02369 26.2692 7.34027 26.4645 7.53553C26.6597 7.7308 26.9763 7.7308 27.1716 7.53553L30.3536 4.35355ZM0 4.5H30V3.5H0V4.5Z"
                    fill="#2E3139" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="goal">
    <div class="container">
      <div class="line-wrapper">
        <div class="goal__inner">
          <div class="goal__title">
            <h2>{{ $t('cases_page.kultura_kavy.goal_title') }}</h2>
          </div>
        </div>
        <ul>
          <li v-for="(content, index) in $tm('cases_page.kultura_kavy.goal_title_list')" :key="index">{{ $rt(content) }}
          </li>
        </ul>
      </div>
    </div>
  </section>

  <section class="development">
    <div class="container">
      <div class="line-wrapper">
        <div class="development__inner">
          <div class="development__info">
            <div class="development__info-wrapper">
              <div>
                <h2 class="development__info-title">{{ $t('cases_page.kultura_kavy.development_title') }}</h2>
                <p class="development__info-text" v-for="(content, index) in $tm('cases_page.kultura_kavy.development_desc')" :key="index">
                  {{ $rt(content) }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <picture>
          <source srcset="../assets/cases/kulturaKavy/Kultura_kavy_dev.webp" type="image/webp">
          <img class="development__image" src="../assets/cases/kulturaKavy/Kultura_kavy_dev.png" alt="" loading="lazy" />
        </picture>
      </div>
    </div>
  </section>

  <section class="challenge">
    <div class="container">
      <div class="line-wrapper">
        <div class="challenge__inner">
          <div class="challenge__info">
            <div class="challenge__info-wrapper">
              <div>
                <h2 class="challenge__info-title">{{ $t('cases_page.kultura_kavy.challenge-title') }}</h2>
                <p class="challenge__info-text" v-for="(content, index) in $tm('cases_page.kultura_kavy.challenge-desc')" :key="index">
                  {{ $rt(content) }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <picture>
          <source srcset="../assets/cases/kulturaKavy/Kultura_kavy_challenge.webp" type="image/webp">
          <img class="challenge__image" src="../assets/cases/kulturaKavy/Kultura_kavy_challenge.png" alt="" loading="lazy" />
        </picture>
      </div>
    </div>
  </section>

  <section class="result">
    <div class="container">
      <h2>{{ $t('cases_page.kultura_kavy.result_title') }}</h2>
      <p class="result__text">{{ $t('cases_page.kultura_kavy.result_text') }}</p>
        <div class="result__list__title fonts__p18-bold">{{ $t('cases_page.kultura_kavy.done.title') }}</div>
        <ul>
          <li class="result__list__item" v-for="(content, index) in $tm('cases_page.kultura_kavy.done.list')" :key="index">{{ $rt(content) }}</li>
        </ul>
    </div>
    <WhatWasDone 
      :leftImg="require('../assets/cases/kulturaKavy/kulturaKavy_result_left.png')"
      :rightImg="require('../assets/cases/kulturaKavy/kulturaKavy_result_right.png')"
    />

  </section>

  <RecentProjects2 />
  <section class="contact">
    <Contact />
  </section>

</template>

<script>

import Contact from "../components/ContactsNew";
import WhatWasDone from "../components/WhatWasDone";
import { computed, reactive } from "vue";
import { useHead } from "@vueuse/head";
import RecentProjects2 from "../components/RecentProjects2";

export default {
  name: "KulturaKavy",
  components: {
    WhatWasDone,
    RecentProjects2,
    Contact,
  },
  setup() {
    const siteData = reactive({
      title: "Webflow-Powered Website For Kultura Kavy Coffee Brand",
      description: "Webmil web-production shares a case study on how we designed a fast-loading site with clear products visualization for a leading printing company",
      keywords: "rebranding, software development",
      robots: "index"
    })

    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description)
        },
        {
          name: `keywords`,
          content: computed(() => siteData.keywords)
        },
        {
          name: `robots`,
          content: computed(() => siteData.robots)
        },
      ],
    })
  },
  data: () => ({

    cases: [
      { name: 'Launch the site', path: 'https://www.kulturakavy.com.ua/' }
    ],
    recentCases: [
      { id: 1, name: 'View all cases', path: '/cases/' }
    ],
  }),
  methods: {

  },
  mounted() {

   },
  unmounted() {
   
  },
}
</script>

<style lang="scss">
@import "../base/styles/media";
@import "../base/styles/fonts.scss";

.project{
  margin-top: 64px !important;
@media(max-width: 575px) {
  h2{
    font-size: 24px !important;
  }
  margin-top: 0 !important;
}
}


.result {
  margin-top: 96px;
  @media(max-width: 575px) {
    margin-top: 0;
  }
  ul{
    @extend .fonts__ul;
  }
  .done_img{
    background: #EFEDE7 !important;
  }
    &__text{
      max-width: 521px;
      padding-bottom: 96px;
      @media(max-width: 575px) {
        padding-bottom: 0;
        margin-bottom: 48px;
      }
    }
    &__list {
      &__item {
        margin-left: 30px;
        @media(max-width: 575px) {
          margin-left: 0;
        }
      }
    }
}

.line-wrapper {
  padding-bottom: 96px;
  border-bottom: 1px solid #DADADA;
  @media(max-width: 575px) {
    padding-bottom: 48px;
  }
}

.contact {
  margin-top: 96px;
  overflow: hidden;
}

.look {
  margin-top: 154px;

  @media(max-width: 991px) {
    margin-top: 135px;
  }

  @media(max-width: 575px) {
    margin-top: 96px;
  }

  &__title {
    h1 {
      max-width: 882px;
      color: #2E3139;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #2E3139;
    max-width: 442px;
    margin-top: 30px;

    @media(max-width: 991px) {
      font-size: 18px;
      line-height: 30px;
      margin-top: 20px;
      max-width: 400px;
    }

    @media(max-width: 575px) {
      font-size: 14px;
      line-height: 24px;
      margin-top: 10px;
    }
  }
}

.presentation {
  position: relative;
  margin-top: 48px;

  .container {
    padding: 0 12px;
    @media(max-width: 575px) {
      height: 175px;
      overflow: hidden;
    }
  }

  @media(max-width: 991px) {
    margin-top: 48px;
  }

  @media(max-width: 575px) {
    margin-top: 45px;
    padding-top: 0%;
  }

  &__image {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.about {
  margin-top: 48px;

  .container {
    padding: 0 12px;
  }

  @media(max-width: 991px) {
    margin-top: 48px;
  }

  @media(max-width: 991px) {
    margin-top: 48px;
  }

  @media(max-width: 575px) {
    margin-top: 30px;

    .container {
      padding: 0 12px 48px 12px;
    }
  }

  @media(min-width: 992px) {
    &__inner {
      display: flex;
      justify-content: space-between;
    }
  }

  &__tech {
    flex: 0 1 50%;

    @media(min-width: 992px) {
      padding-top: 30px;
    }

    &-item {
      &:not(:first-child) {
        margin-top: 30px;

        @media(max-width: 991px) {
          margin-top: 30px;
        }

        @media(max-width: 575px) {
          margin-top: 0px;
        }
      }

      @media(max-width: 575px) {
        display: flex;
        align-items: center;
      }
    }

    &-desc {
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: #2E3139;
      position: relative;

      @media(max-width: 991px) {
        font-size: 20px;
        line-height: 30px;
      }

      @media(max-width: 575px) {
        font-size: 18px;
        line-height: 24px;
        position: relative;

        &:after {
          content: ':';
          position: absolute;
          top: 0;
          right: -5px;
          display: inline-block;
          font-size: 17px;
          margin-left: -3px;
        }
      }

      @media(max-width: 575px) {
        font-size: 14px;
        line-height: 24px;

        &:after {
          content: ':';
          position: absolute;
          bottom: 0;
          right: -5px;
          display: inline-block;
          font-size: 13px;
        }
      }
    }

    span {
      display: inline-block;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: #2E3139;

      @media (min-width: 576px) {
        margin-top: 10px;
      }

      @media (max-width: 575px) {
        margin-left: 10px;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  &__info {
    flex: 0 1 50%;

    @media(min-width: 992px) {
      margin-left: 20px;
    }

    @media(max-width: 991px) {
      margin-top: 80px;
    }

    @media(max-width: 575px) {
      margin-top: 30px;
    }

    &-text {
      max-width: 502px;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: #2E3139;
      margin-top: 30px;

      @media(max-width: 991px) {
        margin-top: 20px;
      }

      @media(max-width: 575px) {
        margin-top: 10px;
      }

      @media(max-width: 575px) {
        font-size: 14px;
        line-height: 24px;
      }
    }

    &-btn {
      margin-top: 30px;

      @media(max-width: 991px) {
        margin-top: 30px;
      }

      @media(max-width: 575px) {
        margin-top: 30px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

.goal {
  margin-top: 96px;
  .container {
    padding: 0 12px;
  }

  @media(max-width: 991px) {
    margin-top: 48px;
  }

  @media(max-width: 575px) {
    margin-top: 0;

    .container {
      padding: 0 12px 48px 12px;
    }
  }

  ul {
   @extend .fonts__ul;
  }

  @media(min-width: 992px) {
    &__inner {
      display: flex;
      justify-content: space-between;
    }
  }

  &__main {
    flex: 0 1 502px;
    padding-top: 20px;

    @media(max-width: 575px) {
      padding-top: 10px;
    }

    @media(min-width: 992px) {
      margin-left: 100px;
    }

    &-text {
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: #2E3139;

      @media(max-width: 575px) {
        font-size: 14px;
        line-height: 24px;
      }
    }

    span {
      display: inline-block;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      color: #2E3139;
      margin-top: 30px;

      @media(max-width: 575px) {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}

.challenge,
.development {
  margin-top: 96px;

  .container {

    //padding: 0;
    img {
      margin-top: 30px;
      width: 100%;
    }
  }

  @media(max-width: 991px) {
    margin-top: 48px;
  }

  @media(max-width: 575px) {
    margin-top: 0;

    .container {
      padding: 0 12px 48px 12px;
    }
  }

  @media(min-width: 992px) {
    &__inner {
      display: flex;
      justify-content: space-between;
    }
  }

  &__info {
    &-text {
      max-width: 564px !important;
    }

    span {
      display: inline-block;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      color: #2E3139;
      margin-top: 30px;

      @media(max-width: 575px) {
        font-size: 14px;
        line-height: 24px;
      }
    }

    ul {
      margin-top: 12px;
    }
  }

  &__site {
    flex: 0 1 606px;

    @media(min-width: 992px) {
      margin-left: 100px;
    }

    @media(max-width: 1399px) {
      flex: 0 1 560px;
    }

    @media(max-width: 1199px) {
      flex: 0 1 470px;
    }

    @media(max-width: 991px) {
      margin-top: 60px;
    }

    img {
      width: 100%;
    }
  }
}

</style>
