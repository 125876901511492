<template>
    <div class="contact_us">
        <div class="contact_us__container">
            <div class="contact_us__form">
                <h2 class="contact_us__title fonts__h1">{{$t('webflow_page.contact_us.title')}}</h2>
                <p class="contact_us-text fonts__p18">{{$t('webflow_page.contact_us.text')}}</p>
                <ButtonWhite 
                    buttonText="webflow_page.contact_us.button"
                    buttonLink="/contacts"
                    class="fonts__h3 contact_us__button"
                />
            </div>
        </div>
        <div class="contact_us__img">
            <img src="../assets/contact_us.png" alt="" loading="lazy" />
        </div>
    </div>
</template>

<script>
import ButtonWhite from "./buttons/ButtonWhite.vue";
export default {
    name: "ContactsNew",
    components: {ButtonWhite},
    methods: {}
}
</script>


<style lang="scss" scoped>
@import "../base/styles/fonts.scss";

.contact_us{
    position: relative;
    padding-bottom: 93px;
    &__button{
        padding: 12px 20px ;
    }
    &__form{
        width: 100%;
        max-width: 522px;
        margin-left: auto;
        margin-right: 292px;
        @media(max-width: 992px){
            margin-right: 152px;
        }
        @media(max-width: 768px){
            margin-right: 30px;
        }
        @media(max-width: 578px){
            max-width: 320px;
            padding: 0 12px;
            margin: 0 auto;
        }
    }
    &-text{
        margin-top: 30px;
        margin-bottom: 60px;
        min-height: 112px;
        @media(max-width: 768px){
            margin-bottom: 30px;
        }
    }
    &__container{
        background: rgba(212, 212, 212, 0.1);
        position: relative;
        z-index: 1;
        width: 100%;
        min-height: 378px;
        backdrop-filter: blur(10px);
        right: calc(((100vw - 1140px) / 2) + 160px);
 
        @media(max-width: 992px){
            right: calc(((100vw - 1140px) / 2) + 220px);
        }
        @media(max-width: 768px){
            right: calc(((100vw - 1140px) / 2) + 270px);
            min-height: 308px;
        }
        @media(max-width: 578px){
            left: 0;
            width: calc(100vw - 30px);
        }
    }
    &__img{
        position: absolute;
        width: 1400px;
        bottom: 0;
        right: 0;
        z-index: 0;
        height: 181px;
        overflow: hidden;
        left: calc(((100vw - 1140px) / 2) + 360px);
 
        @media(max-width: 1200px){
            left: calc(((100vw - 960px) / 2) + 270px);
        }
        @media(max-width: 992px){
            left: calc(((100vw - 720px) / 2) + 200px);
        }
        @media(max-width: 768px){
            left: calc(((100vw - 720px) / 2) + 270px);
            height: 151px;
        }
        @media(max-width: 578px){
            left: calc(((100vw) / 2) );
            height: 151px;
        }

        img{
            width: 100%;
            object-fit: cover;
        }
    }
}


</style>