<template>
    <div class="done_img">
      <div class="container">
        <div class="done_img__img__container">
          <div class="done_img__img__container-left">
            <img :src="leftImg" alt="" loading="lazy" />
          </div>
          <div class="done_img__img__container-right">
            <img :src="rightImg" alt="" loading="lazy" />
          </div>
        </div>
      </div>
    </div>
</template>
  
<script>
  export default {
    name: "WhatWasDone",
    components: { },
    props: {
    leftImg: {
      type: String,
    },
    rightImg: {
      type: String,
    }
  },
  }
</script>
  
<style lang="scss" scoped>
  @import "../base/styles/fonts";
  
  .done_img{
    margin-top: 96px;
    background: #0D1116;
    @media (max-width: 575px) {
      margin-top: 30px;
    }
    &__img{

        &__container{
          display: flex;
          column-gap: 24px;

          &-left{
            margin-top: 64px;
            width: 50%;
            @media (max-width: 575px) {
              margin-top: 20px;
            }

            img{
              width: 100%;
              display: flex;
            }
          }
          &-right{
            width: 50%;
            img{
              width: 100%;
              display: flex;
            }
          }
        }
   
      }
}
</style>
  